.headerWrapper {
    padding-left: 20px;
    width: 100%;
    display: flex;
    border-bottom: 6px solid #CDF6A7;
    position: fixed;
    user-select: none;
    z-index: 1;
    background-color: #f7f7f7;
}

.headerContainer {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
    color: #434343;
}

.headerLogo {
    position: absolute;
    left: 40px;
}

.headerDescription {
    color: #ecf4f8;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
}

.companyLogoContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.companyLogoPicture {
    width: 60px;
    padding-bottom: 10px;
}

.headerTitle {
    font-weight: 600;
    font-size: 44px;
    line-height: 40px;
}

.headerAddress, .headerSchedule {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
}

.headerAddress {
    cursor: pointer;
}

.headerTelephone {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #d6d6d6;
    text-decoration: none;
}

.headerTelephone:hover, .headerAddress:hover {
    text-decoration: underline;
    color: #fff;
}

.cellPhoneBlock {
    display: flex;
    align-items: center;
    gap: 20px;
}

.headerContacts {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tabList {
    height: 100%;
    display: flex;
    gap: 16px;
    font-size: 24px;
    font-weight: 700;
}

.tabElement {
    height: 100%;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tabElement:hover {
    background-color: #f1f1f1;
    text-decoration: underline;
    border-bottom: 6px solid #7FAB5E;
}

@media (max-width: 1360px) {
    .headerContainer {
        padding-top: 20px;
        height: 360px;
        flex-direction: column;
        justify-content: space-around;
    }

    .headerContacts {
        align-items: center;
        gap: 5px;
    }
}

/*@media (max-width: 400px) {*/
/*    .headerTitle {*/
/*        font-size: 28px;*/
/*    }*/

/*    .headerCity, .headerAddress, .headerSchedule, .headerTelephone {*/
/*        font-size: 18px;*/
/*    }*/
/*}*/

/*@media (max-width: 300px) {*/
/*    .headerContainer {*/
/*        gap: 6px;*/
/*    }*/

/*    .headerTitle {*/
/*        font-size: 24px;*/
/*    }*/

/*    .headerCity, .headerContacts, .headerSchedule, .headerTelephone {*/
/*        font-size: 14px;*/
/*    }*/
/*}*/