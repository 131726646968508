.wrapper {
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #434343;
    min-height: 800px;
}

.block {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paragraph {
    text-align: justify;
    font-size: 20px;
}

.list {
    width: 1024px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 20px;
}

.mapWrapper {
    width: 100%;
    height: 100px;
}

.link {
    font-weight: 600;
    color: #434343;
}

.link:hover {
    color: #000;
}

.downloadLink {
    font-size: 20px;
    font-weight: 600;
    color: #434343;
    margin-bottom: 20px;
}

.downloadLink:hover {
    color: #000;
}

.href {
    color: #434343;
    text-decoration: underline;
    margin-bottom: 10px;
    cursor: pointer;
}

.href:hover {
    color: #000;
}
