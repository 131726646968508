.dropdownWrapper {
    position: fixed;
    left: calc(50vw - 300px);
    top: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
    user-select: none;
}

.dropdownElement {
    height: 30px;
    width: 600px;
    font-weight: 600;
    font-size: 16px;
    color: #434343;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-right: 6px solid #def6c8;
}

.dropdownElement:hover {
    background-color: #f1f1f1;
    text-decoration: underline;
    border-right: 6px solid #7FAB5E;
}

.minimizedDropdown {
    position: fixed;
    left: 0;
    top: calc(50vh - 300px + 42px);
    height: 600px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7FAB5E;
    cursor: pointer;
}

.minimizedDropdown:hover {
    background-color: #b2d094;
}