.wrapper {
    position: relative;
}

.backgroundImage {
    background: url('../assets/backgroundImage1.webp') no-repeat center center/cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.cookieAttention {
    max-width: 2200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.listHrefContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: #f1f1f1;
    padding: 34px 20px 38px 20px;
    cursor: pointer;
}

.listHrefContainer:hover {
    background-color: #2b60c7;
}

.listHref {
    width: 600px;
    font-weight: 600;
    font-size: 32px;
    text-shadow: 1px 0 1px #000,
    0 1px 1px #000,
    -1px 0 1px #000,
    0 -1px 1px #000;
    color: #fff;
    text-decoration: none;
    text-align: center;
}

.listHref:hover {
    color: #fff;
}

/*@media (max-width: 1360px) {*/
/*    .cookieAttention {*/
/*        gap: 0;*/
/*    }*/
/*}*/

/*@media (max-width: 640px) {*/
/*    .listHrefContainer {*/
/*        width: 300px;*/
/*    }*/

/*    .listHref {*/
/*        font-size: 20px;*/
/*    }*/
/*}*/