.footerWrapper {
    height: 120px;

}

.footerContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #fff;
    color: #434343;
    font-size: 18px;
    margin-top: 20px;
    background: #def6c8;
    padding: 30px 0;
}

.footerTitle {
    font-size: 24px;
    font-weight: 600;
}

.footerSign {
    font-weight: 600;
}

.footerTelephone, .footerAddress {
    font-weight: 600;
    color: #434343;
    text-decoration: none;
    cursor: pointer;
}

.footerTelephone:hover, .footerAddress:hover {
    color: #545454;
    text-decoration: underline;
}

.cellPhoneBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}

/*@media (max-width: 1360px) {*/
/*    .footerContainer {*/
/*        height: 100%;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        align-items: center;*/
/*        gap: 6px;*/
/*    }*/
/*}*/
