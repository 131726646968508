.formWrapper {
    height: 400px;
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
}

.formFloor {
    display: flex;
    gap: 10px;
}

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 0.1;
}

.inputField {
    height: 50px;
    width: 300px;
    border: 1px solid #434343;
    border-radius: 2px;
    font-size: 18px;
    padding: 0 10px;
}

.sendButton {
    outline: none;
    height: 50px;
    width: 140px;
    border: 1px solid #434343;
    border-radius: 2px;
    background-color: #545454;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.successSign {
    position: absolute;
    bottom: -30px;
    width: 400px;
    height: 50px;
    border: 1px solid #44b22b;
    border-radius: 2px;
    background-color: #fff;
    color: #44b22b;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sendFailure {
    position: absolute;
    bottom: -30px;
    width: 500px;
    height: 50px;
    border: 1px solid #ea3535;
    border-radius: 2px;
    background-color: #fff;
    color: #ea3535;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}